import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <section class="client_section layout_padding">
      <div class="container">
        <div class="heading_container">
          <h2>What Says Our Client</h2>
        </div>
        <div class="client_container">
          <div class="carousel-wrap">
            <Slider {...settings}>
              <div class="item">
                <div class="box">
                  <div class="img-box">
                    <img src="/images/client-1.jpg" alt="" class="img-1" />
                  </div>
                  <div class="detail-box">
                    <h5>Mark Evans</h5>
                    <h6>Magna</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim
                    </p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="box">
                  <div class="img-box">
                    <img src="/images/client-2.jpg" alt="" class="img-1" />
                  </div>
                  <div class="detail-box">
                    <h5>Anthony White</h5>
                    <h6>Magna</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
            {/* <div class="item">
                <div class="box">
                  <div class="img-box">
                    <img src="/images/client-2.jpg" alt="" class="img-1" />
                  </div>
                  <div class="detail-box">
                    <h5>Anthony White</h5>
                    <h6>Magna</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim
                    </p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="box">
                  <div class="img-box">
                    <img src="/images/client-1.jpg" alt="" class="img-1" />
                  </div>
                  <div class="detail-box">
                    <h5>Mark Evans</h5>
                    <h6>Magna</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim
                    </p>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="box">
                  <div class="img-box">
                    <img src="/images/client-2.jpg" alt="" class="img-1" />
                  </div>
                  <div class="detail-box">
                    <h5>Anthony White</h5>
                    <h6>Magna</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim
                    </p>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
