import React from "react";

const Services = () => {
  return (
    <div>
      <section class="service_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Our services</h2>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="box ">
                <div class="img-box">
                  <img src="images/s1.png" alt="" />
                </div>
                <div class="detail-box">
                  <h6>Business Visa</h6>
                  <p>
                    Minima consequatur architecto eaque assumenda ipsam itaque
                    quia eum in doloribus debitis impedit ut minus tenetur
                    corrupti excepturi ullam.
                  </p>
                  <a href="">Read More</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="box ">
                <div class="img-box">
                  <img src="images/s2.png" alt="" />
                </div>
                <div class="detail-box">
                  <h6>Work Visa</h6>
                  <p>
                    Minima consequatur architecto eaque assumenda ipsam itaque
                    quia eum in doloribus debitis impedit ut minus tenetur
                    corrupti excepturi ullam.
                  </p>
                  <a href="">Read More</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="box ">
                <div class="img-box">
                  <img src="images/s3.png" alt="" />
                </div>
                <div class="detail-box">
                  <h6>Tourist Visa</h6>
                  <p>
                    Minima consequatur architecto eaque assumenda ipsam itaque
                    quia eum in doloribus debitis impedit ut minus tenetur
                    corrupti excepturi ullam.
                  </p>
                  <a href="">Read More</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="box ">
                <div class="img-box">
                  <img src="images/s4.png" alt="" />
                </div>
                <div class="detail-box">
                  <h6>Student Visa</h6>
                  <p>
                    Minima consequatur architecto eaque assumenda ipsam itaque
                    quia eum in doloribus debitis impedit ut minus tenetur
                    corrupti excepturi ullam.
                  </p>
                  <a href="">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
