import React from "react";

const Countries = () => {
  return (
    <section class="country_section layout_padding">
      <div class="container">
        <div class="heading_container heading_center">
          <h2>Choose Country</h2>
          <p>Select country you want to apply visa for</p>
        </div>
        <div class="row">
          <div class="col-md-4">
            <a href="" class="box">
              <img src="images/c1.jpg" alt="" />
              <div class="detail-box">
                <h3>France</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="images/c2.jpg" alt="" />
              <div class="detail-box">
                <h3>Canada</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="images/c3.jpg" alt="" />
              <div class="detail-box">
                <h3>United States</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="images/c4.jpg" alt="" />
              <div class="detail-box">
                <h3>New Zealand</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="images/c5.jpg" alt="" />
              <div class="detail-box">
                <h3>Australia</h3>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="" class="box">
              <img src="images/c6.jpg" alt="" />
              <div class="detail-box">
                <h3>Spain</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Countries;
