import Header from "./components/Header";
import Banner from "./containers/Banner";
import Services from "./containers/Services";
import AboutUs from "./containers/AboutUs";
import Countries from "./containers/Countries";
import ContactUs from "./containers/ContactUs";
import Testimonials from "./containers/Testimonials";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <div class="hero_area">
        <Header />
        <Banner />
      </div>
      <Services />
      <AboutUs />
      <Countries />
      <ContactUs />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
