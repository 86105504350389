import React from "react";

const Footer = () => {
  return (
    <>
      <section class="info_section ">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <div class="info_logo">
                <a class="navbar-brand" href="index.html">
                  <span>Videsh Vibes</span>
                </a>
                <p>
                  Dolor sit amet, consectetur magna aliqua. Ut enim ad minim
                  veniam, quisdotempor incididunt r
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="info_links">
                <h5>Useful Link</h5>
                <ul>
                  <li>
                    <a href="">Dolor sit amet, consectetur</a>
                  </li>
                  <li>
                    <a href="">Magna aliqua. Ut enim ad</a>
                  </li>
                  <li>
                    <a href="">Minim veniam</a>
                  </li>
                  <li>
                    <a href="">Quisdotempor incididunt r</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="info_info">
                <h5>Contact Us</h5>
              </div>
              <div class="info_contact">
                <a href="" class="">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span>9th floor, APMC Krushi Bazaar, Surat</span>
                </a>
                <a href="" class="">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>Call : +91 9106390481</span>
                </a>
                <a href="" class="">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>videshvibesimmigration@gmail.com</span>
                </a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="info_form ">
                <h5>Newsletter</h5>
                <form action="#">
                  <input type="email" placeholder="Enter your email" />
                  <button>Subscribe</button>
                </form>
                <div class="social_box">
                  <a href="">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-youtube" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <footer class="container-fluid footer_section">
        <p>
          &copy; <span id="displayYear"></span> All Rights Reserved By
          <a href="https://html.design/">Free Html Templates</a>
        </p>
      </footer> */}
    </>
  );
};

export default Footer;
